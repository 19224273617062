<template>
  <div class="container">
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <div class="header">
          <router-link to="/activity-eva" tag="div" class="back">
            <div class="ico"></div>
            <div>返回</div>
          </router-link>
        </div>
        <div class="tab">
          <div class="tab-item active">电子书</div>
          <router-link tag="div" to="rank-eva" class="tab-item">排行榜</router-link>
        </div>
        <div class="books">
          <router-link tag="div" :to="`home/book-detail/${item.resources_guid}/码上悦读`" class="item" v-for="(item,index) in data" :key="index">
            <div class="cover">
              <img class="img" :src="item.cover.media_file"/>
            </div>
            <div class="info">
              <div class="title">{{ item.resources_name }}</div>
              <div class="desc">{{ item.resources_desc }}</div>
              <div class="author">
                <div class="ico"></div>
                <div>作者：{{ item.resources_author }}</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </scroll>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import { books } from '@/api/Evaluations'
import { Toast } from 'vant'

export default {
  name: 'BooksEva',
  components: { Scroll },
  data () {
    return {
      data: []
    }
  },
  mounted () {
    this._books()
  },
  methods: {
    _books () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      books().then(res => {
        const {
          code,
          msg,
          data
        } = res
        if (code === 200) {
          this.data = data
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.img
  width 100%
  height 100%

.container
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden
    background rgba(248, 248, 248, 1)

    .header
      width 100%
      height 212px
      bg-image2('assets/activity-evaluation/header-bg')
      background-size 100% 100%
      background-repeat no-repeat

      .back
        display flex
        align-items center
        justify-content center
        width 200px
        height 80px
        font-size 26px
        color rgba(51, 51, 51, 1)

        .ico
          width 16px
          height 28px
          margin-right 10px
          bg-image('~@/assets/img/reading/back')
          background-size 100% 100%
          background-repeat no-repeat

    .tab
      display flex
      align-items center
      justify-content space-around
      width 670px
      height 104px
      margin -90px auto 0 auto
      background rgba(255, 255, 255, 1)
      border-radius 20px

      .tab-item
        display flex
        align-items center
        justify-content center
        width 202px
        height 60px
        font-size 30px
        color rgba(60, 60, 60, 1)

        &.active
          color rgba(255, 255, 255, 1)
          bg-image2('assets/activity-evaluation/tab-active')
          background-size 100% 100%
          background-repeat no-repeat

    .books
      width 670px
      margin 0 auto

      .item
        display flex
        align-items center
        width 100%
        margin-top 64px

        .cover
          width 208px
          height 290px
          border-radius 14px
          overflow hidden

        .info
          display flex
          align-content space-around
          flex-wrap wrap
          width 462px
          height 256px
          padding 0 28px
          box-sizing border-box
          background rgba(255, 255, 255, 1)
          border-right 0 16px 16px 0

          .title
            width 100%
            line-height 36px
            font-size 28px
            color rgba(51, 51, 51, 1)
            no-wrap2(2)

          .desc
            width 100%
            font-size 24px
            color rgba(153, 153, 153, 1)
            margin-top 24px
            no-wrap()

          .author
            width 100%
            font-size 24px
            color rgba(153, 153, 153, 1)
            no-wrap()
</style>
